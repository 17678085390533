import { Controller } from '@hotwired/stimulus'
import { debounce } from 'lodash'
import Sortable from 'sortablejs'
import { stringifyFormState, isEventChangesForm } from '../../../../libs/form-state'

export default class extends Controller {
  static targets = [
    'submit',
    'saveChangesButton',
    'contentDrawer',
  ]

  static values = { lockWindow: Boolean, formId: String }

  _initFormState = ''

  _handleFormChange = debounce(() => {
    if (this.form.getAttribute('aria-busy') === 'true') return

    const currentState = stringifyFormState(this.form)
    const isChanged = this._initFormState !== currentState

    this.saveChangesButtonTarget.disabled = !isChanged
  }, 300)

  handleFormChange = e => {
    if (isEventChangesForm(e)) {
      this._handleFormChange()
    }
  }

  get form() {
    return this.element.querySelector(`form#${this.formIdValue}`)
  }

  submitForm(e) {
    e.preventDefault()
    e.currentTarget.form.requestSubmit()
  }

  onDsFormElementInitialized() {
    this._initFormState = stringifyFormState(this.form)
  }

  connect() {
    this.onDsFormElementInitialized = debounce(this.onDsFormElementInitialized, 300).bind(this)
    this.onDsFormElementInitialized()

    if (this.lockWindowValue) {
      this.setupWindowLock()
    }
  }

  setupWindowLock() {
    window.onbeforeunload = () => this.saveChangesButtonTarget.disabled ? undefined : true
  }

  disconnect() {
    this.element.removeEventListener('turbo:submit-end', this.onTurboSubmitEnd)
  }

  onTurboSubmitStart = () => {
    this.saveChangesButtonTarget.disabled = true
    this.submitButtonText = 'Saving...'
    this.element.setAttribute('aria-busy', 'true')
  }

  onTurboSubmitEnd = e => {
    if (!e.detail.success) {
      this.submitButtonText = 'Save changes'
      this.saveChangesButtonTarget.disabled = false
      this.element.removeAttribute('aria-busy')
    }
  }

  addContent = () => {
    const controller = this.application.getControllerForElementAndIdentifier(this.contentDrawerTarget, 'beam--modal')
    controller.show()
  }

  set submitButtonText(value) {
    this.saveChangesButtonTarget.querySelector('[data-role="content"]').innerText = value
  }

  get submitButtonText() {
    return this.saveChangesButtonTarget.querySelector('[data-role="content"]').innerText
  }
}
