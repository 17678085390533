/* eslint-disable no-param-reassign */
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['chosenCurrency', 'form']

  static values = {
    availablePaypalCurrencies: Array,
    isPaypal: Boolean
  }

  handleSubmitButton(e) {
    e.preventDefault()

    const selectedCurrency = this.application.getControllerForElementAndIdentifier(this.chosenCurrencyTarget.querySelector('[data-controller="beam--combobox-list"]'), 'beam--combobox-list').selectedItems[0]?.value

    if (selectedCurrency != null && !this.availablePaypalCurrenciesValue.includes(selectedCurrency) && this.isPaypalValue) {
      this.showConfirmationModal()
    } else {
      this.submitForm()
    }
  }

  showConfirmationModal() {
    document.getElementById('general_settings_confirmation').open()
  }

  closeConfirmationModal() {
    document.getElementById('general_settings_confirmation').close()
  }

  submitForm() {
    this.formTarget.requestSubmit()
  }

  enableLocalizedPricing() {
    const url = new URL(window.location.href)
    url.searchParams.append('show_localized_currencies_widget', 'true')
    window.requestAnimationFrame(() => {
      window.location.href = url
    })
  }
}
