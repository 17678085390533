import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    initiallyOpen: Boolean,
    destroyOnClose: Boolean
  }

  static targets = ['badge', 'switcher']

  connect() {
    document.addEventListener('click', (event) => {
      const withinBoundaries = event.composedPath().includes(this.switcherTarget) ||
        event.composedPath().includes(this.badgeTarget)

      if (!withinBoundaries) {
        this.hideSwitcher()
      }
    })
  }

  toggleSwitcher() {
    this.switcherTarget.classList.toggle('hidden')
    this.toggleBadgeAriaExpanded()
  }

  hideSwitcher() {
    this.switcherTarget.classList.add('hidden')
    this.hideBadgeAriaExpanded()
  }

  toggleBadgeAriaExpanded() {
    let newExpandedValue = true
    if (this.badgeTarget.getAttribute('aria-expanded') == "true") {
      newExpandedValue = false
    }

    this.badgeTarget.setAttribute('aria-expanded', newExpandedValue)
  }

  hideBadgeAriaExpanded() {
    this.badgeTarget.setAttribute('aria-expanded', false)
  }

  submitForm(e) {
    e.currentTarget.requestSubmit()
    this.hideSwitcher()
  }

}
