import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'

export default class extends Controller {
  static targets = ['target', 'menuItems', 'submenuItems', 'templateMenuItem', 'templateSubmenuItem', 'form']

  maxMenuItemsMessageWasShowed = false

  static values = {
    rowSelector: {
      type: String,
      default: '.navigation-form-row'
    },
    submenuContainer: {
      type: String,
      default: '.navigation-form-submenu-container'
    },
    submenuTarget: {
      type: String,
      default: '.navigation-form-submenu-target'
    }
  }

  initialState = ''

  isChanged = false

  connect() {
    this.initialFormState = this.currentFormState

    this.element.addEventListener('input', this.handleFormChange)
    this.element.addEventListener('change', this.handleFormChange)
    this.lockWindow()

    window.addEventListener('turbo:before-visit', event => {
      if (!this.isChanged || this.isIos) return
      if (!window.confirm('Are you sure you want to leave? All changes will be lost')) {
        event.preventDefault()
      }
    })

    window.addEventListener('turbo:before-fetch-response', event => {
      const { response } = event.detail.fetchResponse
      this.isChanged = false
      if (response.redirected) {
        event.preventDefault()
        window.Turbo.visit(response.url)
      }
    })

    this.sortable = new Sortable(this.menuItemsTarget, this.sortableOptions)
    this.submenuItemsTargets.forEach(target => new Sortable(target, this.sortableOptions))

    this.cancelButton = this.element.querySelector('#cancelButton')
    if (this.cancelButton) {
      this.cancelButton.addEventListener('click', this.handleCancelButtonClick)
    }
  }

  disconnect() {
    this.element.removeEventListener('input', this.handleFormChange)
    this.element.removeEventListener('change', this.handleFormChange)
    this.unlockWindow()
    if (this.cancelButton) {
      this.cancelButton.removeEventListener('click', this.handleCancelButtonClick)
    }
  }

  lockWindow() {
    window.onbeforeunload = event => {
      if (!this.isChanged) return
      event.returnValue = 'Are you sure you want to leave? All changes will be lost'
    }
  }

  unlockWindow() {
    window.onbeforeunload = undefined
  }

  get sortableOptions() {
    return {
      animation: 150,
      swapThreshold: 0.65,
      fallbackOnBody: true,
      handle: '.handle'
    }
  }

  addMenuItem(e) {
    e.preventDefault()
    this.targetTarget.insertAdjacentHTML('beforebegin', this.templateMenuItemTarget.innerHTML)
    this.focusNewInput(this.targetTarget)
    this.checkMenuItemsCount()
    // TODO init sortable
  }

  addSubmenuItem(e) {
    e.preventDefault()

    const renderTarget = e.target.closest(this.submenuContainerValue).querySelector(this.submenuTargetValue)
    renderTarget.insertAdjacentHTML('beforebegin', this.templateSubmenuItemTarget.innerHTML)
    this.focusNewInput(renderTarget)
  }

  removeMenuItem(e) {
    e.preventDefault()

    const row = e.target.closest(this.rowSelectorValue)
    if (row.dataset.newRecord === 'true') {
      row.remove()
    } else {
      row.style.display = 'none'
    }

    const input = row.querySelector("input[name*='_destroy']")
    input.value = '1'
  }

  focusNewInput(node) {
    const currentTitleInput = node.previousElementSibling.querySelector('.form-input--title')
    if (currentTitleInput) {
      currentTitleInput.focus()
      currentTitleInput.scrollIntoView({ behavior: 'smooth' })
    }
  }

  handleFormChange = () => {
    this.isChanged = this.initialFormState !== this.currentFormState
  }

  checkMenuItemsCount() {
    const visibleMenuItems = Array.from(this.targetTarget.parentNode.children).filter(item => Array.from(item.classList).includes('navigation-form-row') && item.style.display !== 'none')
    if (visibleMenuItems.length > 5 && !this.maxMenuItemsMessageWasShowed) {
      this.element.querySelector('#warning-message').open()
      this.maxMenuItemsMessageWasShowed = true
    }
  }

  get isIos() {
    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
      return true
    }
    return navigator.maxTouchPoints
        && navigator.maxTouchPoints > 2
        && /MacIntel/.test(navigator.platform)
  }

  get currentFormState() {
    const menuList = this.targetTarget.parentNode.querySelectorAll('.u-navigation-form-row') || []
    const result = {
      title: this.element.querySelector('.form-input__menu-title')?.value || '',
      list: Array.from(menuList).map((element, index) => {
        const currentItem = element.querySelector('[data-controller="navigation-link"]')
        const id = currentItem.querySelector('#navigation_group_navigation_links_attributes__id')?.value || ''
        const url = currentItem.querySelector('#navigation_group_navigation_links_attributes__url')?.value || ''
        const title = currentItem.querySelector('#navigation_group_navigation_links_attributes__title')?.value || ''
        const dropdownNodeList = element.querySelector('[data-navigation-form-target="submenuItems"]')?.querySelectorAll('.u-navigation-form-row') || []
        const dropdown = Array.from(dropdownNodeList).map((dropdownItem, dropdownIndex) => {
          const dropdownId = dropdownItem.querySelector('#navigation_group_navigation_links_attributes__navigation_links_attributes__id')?.value
          const dropdownTitle = dropdownItem.querySelector('#navigation_group_navigation_links_attributes__navigation_links_attributes__title')?.value
          const dropdownUrl = dropdownItem.querySelector('#navigation_group_navigation_links_attributes__navigation_links_attributes__url')?.value
          return {
            id: dropdownId,
            title: dropdownTitle,
            position: dropdownIndex,
            url: dropdownUrl,
          }
        })
        return {
          id,
          title,
          position: index,
          url,
          dropdown
        }
      })
    }
    return JSON.stringify(result)
  }

  handleCancelButtonClick() {
    const closeModalEvent = new CustomEvent('close-modal')
    window.dispatchEvent(closeModalEvent)
  }
}
