import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'passwordSwitcher',
    'input',
    'passwordStrikedIcon',
    'passwordOpenedIcon',
    'readOnlyCopy',
    'readOnlyCopied',
  ]

  onChangePasswordField = () => {
    const type = this.inputTarget.getAttribute('type') === 'password' ? 'text' : 'password'
    this.inputTarget.setAttribute('type', type)
    if (type === 'password') {
      this.passwordStrikedIconTarget.classList.remove('hidden')
      this.passwordOpenedIconTarget.classList.add('hidden')
    } else {
      this.passwordStrikedIconTarget.classList.add('hidden')
      this.passwordOpenedIconTarget.classList.remove('hidden')
    }
  }

  increaseNumber = () => {
    this.inputTarget.stepUp()
    this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }

  decreaseNumber = () => {
    this.inputTarget.stepDown()
    this.inputTarget.dispatchEvent(new Event('change', { bubbles: true }))
  }

  toggleReadOnlyCopyText = () => {
    this.readOnlyCopyTarget?.classList.toggle('invisible')
    this.readOnlyCopyTarget?.classList.toggle('opacity-0')

    this.readOnlyCopiedTarget?.classList.toggle('invisible')
    this.readOnlyCopiedTarget?.classList.toggle('opacity-0')
  }

  copyTextToClipboard = text => {
    const el = document.createElement('input')
    el.value = text
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  copyValue = async () => {
    this.copyTextToClipboard(this.inputTarget?.value)
    this.toggleReadOnlyCopyText()
    setTimeout(() => {
      this.toggleReadOnlyCopyText()
    }, 4000)
  }
}
