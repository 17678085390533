import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: { type: String, default: '' },
    closeUrl: { type: String, default: '' },
    turboTrainStreamId: { type: String, default: '' },
    userId: { type: String, default: '' }
  }

  connect() {
    const url = new URL(this.urlValue)

    fetch(url, { method: 'POST' }).then(response => {})

    this.turboTrainStream = this.turboTrainStreamIdValue && document.getElementById(this.turboTrainStreamIdValue)
    this.turboTrainStream?.addEventListener('message', this.onStreamMessage)

    window.addEventListener('beforeunload', this.onBeforeUnload)
  }

  disconnect() {
    this.turboTrainStream?.removeEventListener('message', this.onStreamMessage)
    window.removeEventListener('beforeunload', this.onBeforeUnload)
  }

  onStreamMessage = event => {
    const message = event.detail

    const messageType = message.turbo_stream.action

    // workaround for case when user open stream from more than 1 device and close it on one of them
    if (messageType === 'user_close_sessions') {
      const messageData = message.turbo_stream.data

      if (messageData.user_id == this.userIdValue) {
        const url = new URL(this.urlValue)
        fetch(url, { method: 'POST' }).then(response => {})
      }
    }
  }

  onBeforeUnload = () => {
    const closeUrl = new URL(this.closeUrlValue)
    if (window.navigator?.sendBeacon) {
      navigator.sendBeacon(closeUrl, {})
    } else {
      fetch(closeUrl, { method: 'POST' }).then(response => {})
    }
  }
}
