import { createUpload } from '@mux/upchunk'
import * as tus from "tus-js-client"
import API from 'libs/api'
import _debug from 'debug'
import { STATUS } from '../constants'
import { EventBus } from '../EventBus'
import { retry } from '../utils/retry'
import * as Sentry from "@sentry/browser";

const lockWindow = () => {
  window.onbeforeunload = function () { return '' }
}

const unlockWindow = () => {
  window.onbeforeunload = undefined
}

export class SourceItem extends EventBus {
  status = STATUS.INITIALIZED
  progress = 0
  file = undefined
  meta = undefined
  debug = undefined
  loader = undefined
  uploader = undefined

  constructor(file) {
    super()

    this.file = file

    this.meta = {
      filename: file.name,
      last_modified: file.lastModified,
      size: file.size,
    }

    this.debug = _debug(`upload:[${file.name}]`)
    this.debug('Initialized')
    Sentry.captureMessage('Initialized')
  }

  async setMeta(data) {
    this.meta = {
      ...this.meta,
      ...data
    }

    this.debug('Metadata updated', this.meta)
    Sentry.captureMessage('Metadata updated', { extra: this.meta })

  }

  getEncodingData = async () => {
    const { id, type } = this.meta
    const { data } = await retry(() => API.post(`/bullet/contents/uploads/${id}/start`, { type }))
    return data
  }

  uploadToEncodingService = () => {
    let reject, resolve
    const promise = new Promise(async (_resolve, _reject) => {
      reject = _reject
      resolve = _resolve

      try {
        const data = await this.getEncodingData()
        this.setMeta(data)
      } catch (e) {
        console.log(e)
        Sentry.captureException(e)
        this.status = STATUS.ERROR
        return reject(e)
      }

      this.debug('Start uploading')

      if (this.file.size > 7e+10) {
        return reject('Maximum file size is 70 Gb')
      }

      this.status = STATUS.UPLOADING

      if (this.status === STATUS.CANCELED) return resolve()

      if (this.meta.encoding === 'mux') {
        this.uploader = createUpload({
          endpoint: this.meta.url,
          file: this.file,
          chunkSize: 5120,
        })

        this.emit('started')

        this.uploader.on('error', error => {
          this.status = STATUS.ERROR
          this.debug('Error %o', error.details)
          this.emit('error', error.details)
          reject(error.detail)
        });

        this.uploader.on('progress', progress => {
          this.debug('Progress (%d%)', progress.detail)
          this.emit('progress', progress.detail)
          this.progress = progress.detail
        });

        this.uploader.on('success', () => {
          this.status = STATUS.UPLOADED
          this.emit('success', this.meta)
          this.debug('File successfully uploaded')
          resolve();
        });
      } else {
        this.status = STATUS.ERROR
        return reject(new Error('Unknown encoding'))
      }
    })

    this.loader = {
      promise, reject, resolve
    }

    return promise
  }

  cancel = () => {
    this.debug('Upload has been canceled')
    this.emit('cancel')
    Sentry.captureMessage('Upload has been canceled', { extra: this.meta })

    if (this.status === STATUS.INITIALIZED) {
      this.status = STATUS.CANCELED
    }

    this.status = STATUS.CANCELED

    this.loader?.resolve()

    // Mux: https://github.com/muxinc/upchunk/blob/master/src/upchunk.ts#L338
    this.uploader?.abort()

    delete this.uploader
  }

  upload = async () => {
    try {
      lockWindow()
      await this.uploadToEncodingService()
      this.debug('Upload complete')
      Sentry.captureMessage('Upload complete', { extra: this.meta })
      unlockWindow()
    } catch (e) {
      unlockWindow()
      Sentry.captureException(e)
      this.debug('error', e)
      this.emit('error', e)
      throw e
    }
  }
}
