import { Controller } from '@hotwired/stimulus'
import Sortable from 'sortablejs'
import { FetchRequest } from '@rails/request.js'
import { debounce } from 'lodash'

export default class extends Controller {
  static values = {
    reorderUrl: String,
    applySortUrl: String,
    page: { type: Number, default: 1 },
    perPage: { type: Number, default: 75 },
  }

  connect() {
    this.sortable = new Sortable(this.table.querySelector('tbody'), {
      animation: 150,
      ghostClass: 'bg-blue-50',
      chosenClass: 'bg-white',
      handle: '.drag-handle',
      onUpdate: this.onUpdateSortable,
    })

    this.changeSort = debounce(this.changeSort, 100).bind(this)
  }

  disconnect() {
    if (this.sortable) {
      this.sortable.destroy()
    }
  }

  onUpdateSortable = e => {
    const delta = e.newIndex - e.oldIndex
    this.submitReorderForm(delta, e.item.dataset.id)
  }

  submitReorderForm(delta, id) {
    new FetchRequest('post', this.reorderUrlValue, { responseKind: 'turbo-stream', body: { delta: delta, content_id: id } }).perform()
  }

  get table() {
    return this.element.querySelector('table')
  }

  moveTo = e => {
    e.preventDefault()

    const dropdown = this.application.getControllerForElementAndIdentifier(e.target.closest('[data-controller="beam--dropdown"]'), 'beam--dropdown')
    dropdown.close()

    this.setTableLoadingState()

    new FetchRequest('post', e.currentTarget.dataset.url, { responseKind: 'turbo-stream'}).perform()
  }

  randomizeContent = (e) => {
    new FetchRequest('post', e.currentTarget.dataset.url, { responseKind: 'turbo-stream' }).perform()
    document.getElementById(e.currentTarget.dataset.modalId).remove()
    this.setTableLoadingState()
  }

  removeFromCategory = e => {
    new FetchRequest('delete', e.currentTarget.dataset.url, { responseKind: 'turbo-stream' }).perform()
    document.getElementById(e.currentTarget.dataset.modalId).remove()
    this.setTableLoadingState()
  }

  changeSort = e => {
    new FetchRequest('post', this.applySortUrlValue, { responseKind: 'turbo-stream', body: JSON.stringify({ sort_by: e.detail.value }) }).perform()
    this.setTableLoadingState()
  }

  onChangePaginationPage = e => {
    if (e.currentTarget.getAttribute('href') === '#') {
      e.preventDefault()
      return
    }

    this.setTableLoadingState()
  }

  setTableLoadingState() {
    const el = document.getElementById('contents_table')
    el.classList.add('opacity-50', 'pointer-events-none')
  }

  resetTableLoadingState() {
    const el = document.getElementById('contents_table')
    el.classList.remove('opacity-50', 'pointer-events-none')
  }
}
