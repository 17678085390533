import { Controller } from '@hotwired/stimulus'
import Confirm from 'bullet/components/Confirm.vue'
import Vue from 'vue'

export default class extends Controller {
  static targets = ['data', 'form']

  static values = {
    title: String,
    color: { type: String, default: 'danger' },
    confirm: String,
    message: String,
    cancel: String,
    onConfirmEvent: String
  }

  open() {
    window.confirmForm = this.formTarget
    const confirmComponent = new Vue({
      render: h => h(
        Confirm,
        {
          props: {
            confirmColor: this.colorValue,
            confirmText: this.confirmValue,
            title: this.titleValue,
            message: this.messageValue,
            cancelText: this.cancelValue || 'Cancel',
            onConfirm: () => {
              const form = window.confirmForm
              if (form.dataset.hotwired) {

                form.requestSubmit()
              } else {
                form.submit()
              }

              if (this.onConfirmEventValue) {
                const event = new Event(this.onConfirmEventValue)
                window.dispatchEvent(event)
              }

              window.confirmForm = null
            },
            onCancel: () => {},
          },
        },
      ),
    })
    const popstateHandler = () => {
      window.removeEventListener('popstate', popstateHandler)

      // eslint-disable-next-line
      if (!confirmComponent._isMounted) return

      confirmComponent.$destroy()
    }
    const newDiv = document.createElement('div')

    document.body.appendChild(newDiv)
    confirmComponent.$mount(newDiv)

    window.addEventListener('popstate', popstateHandler)
  }
}
